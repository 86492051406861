<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            title="Configuração do núcleo urbano"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                O núcleo urbano foi conformado longitudinalmente aos pés da
                Serra do Caraça, acompanhando o curso do Rio Piracicaba,
                principalmente em sua margem oeste. O distrito, assim como as
                demais comunidades do município de Mariana, é composto ainda por
                propriedades rurais onde se realizam atividades de plantio e
                criação de animais.
              </p>
              <p>
                O conjunto edificado foi implantado em área de planície ou de
                relevo suave, e o entorno é conformado por serras cobertas de
                mata. A maioria das casas foi construída com a fachada frontal
                sobre o limite das vias, em terrenos de dimensões avantajadas,
                onde nos quintais são cultivadas hortas e árvores frutíferas e
                criados animais. O gabarito é predominantemente de um pavimento,
                além de sobrados remanescentes do período colonial, havendo
                edificações contemporâneas pontuais com até três andares,
                destoando do conjunto harmônico de gabarito baixo.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista do conjunto de casas situadas no entorno da Igreja Matriz.
            Fonte: ETII/IPHAN/Mariana, fevereiro de 2022.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_04.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista de edificações situadas no entorno da Igreja Matriz. Fonte:
            ETII/IPHAN/Mariana, fevereiro de 2022.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Ressalta-se que, ao longo do século XX, em todo o município de
                Mariana ocorreram ocupações informais e loteamentos para suprir
                a demanda por moradia advinda da chegada de companhias
                mineradoras e siderúrgicas a partir da década de 1940 e,
                principalmente, na década de 1970. O rápido crescimento do
                distrito de Santa Rita Durão também ocorreu por conta da chegada
                de grandes mineradoras, alterando o conjunto urbano e
                deteriorando o meio ambiente e a paisagem. Foram construídas
                edificações muito diferentes das mais antigas, descaracterizando
                o conjunto, além de ocorrerem algumas perdas que não podem mais
                ser substituídas. O calçamento original das principais vias foi
                retirado por volta de 1978, substituído por asfalto.
              </p>
              <p>
                A Rua do Rosário é marcada pelo eixo que se estabelece entre as
                duas igrejas implantadas nas extremidades, sendo a sul a Igreja
                do Rosário e a norte, a Igreja Matriz de Nossa Senhora de
                Nazaré. As torres dos templos destacam-se na paisagem urbana,
                demonstrando a importância que as igrejas possuíam no período
                colonial dentro do conjunto edificado. Os dois templos, por sua
                importância, foram protegidos por tombamento federal em 1945.
              </p>
              <p>
                Além desses dois templos, destaca-se em Santa Rita Durão uma
                edificação civil conhecida como “Casa com Rótulas e Cruzes”, com
                características arquitetônicas bastante particulares.
                Originalmente era usada como residência e, em 1947, foi doada à
                União pelos proprietários.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Em 02 de dezembro de 1950, foi tombada em âmbito federal pelo
                IPHAN. Localiza-se na Rua do Rosário e foi implantada sobre a
                divisa frontal do terreno. A edificação segue o modelo colonial
                simples, assim como as outras do conjunto original. As paredes
                da casa vistas de fora possuem vãos de portas e janelas
                pequenos. A diferença dela em relação às outras casas é por suas
                esquadrias de janelas possuírem cruzes vazadas na madeira
                lateral do quadro, além das folhas de fechamento com treliças,
                de origem luso-árabe, e que no Brasil ficaram conhecidas como
                adufas, rótulas, muxarabies ou gelosias.
              </p>
              <p>
                Outras casas construídas em Santa Rita Durão na época da
                formação de seu núcleo urbano também resistem à ação do tempo e
                às alterações posteriores. Assim, em Santa Rita pode-se
                encontrar parte de um conjunto arquitetônico que segue os
                modelos urbanos do Período Colonial vivido no Brasil e,
                especialmente, remanescentes dos núcleos coloniais da região
                mineradora das Minas Gerais.
              </p>
              <p>
                Nessas casas é possível reconhecer suas características e
                materiais construtivos originais (esteios de madeiras, janelas,
                portas, paredes de adobe ou pau a pique, telhas de barro, etc.).
                Por sua relevância histórica, artística e cultural, o Núcleo
                Histórico Urbano de Santa Durão foi tombado pelo Município de
                Mariana em 14 de abril de 2019, e já havia sido reconhecido em
                âmbito estadual pelo IEPHA, em 16 de janeiro de 1996.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_05.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da Igreja de Nossa Senhora do Rosário Fonte:
            ETII/IPHAN/Mariana, fevereiro de 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                A população local e visitante possui como área de lazer e
                recreação junto à natureza a Cachoeira do Retiro do Piracicaba.
                O local é cercado por mata densa, onde existem três lagos e uma
                cachoeira com queda de 15 metros de altura. Como produção
                artesanal, destaca-se o forro de esteira fabricado manualmente
                com fibras de taquara, também peças feitas em tricô, crochê e
                bordado.
              </p>
              <p style="text-align: center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_santa_rita_06.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da Igreja Matriz de Nossa Senhora de Nazaré. Fonte:
            ETII/IPHAN/Mariana, 2022.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_07.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Vista da Casa com rótulas. Fonte: ETII/IPHAN/Mariana, fevereiro de
            2021.
          </p>
        </v-col>

        <v-col cols="12" md="3" class="d-flex flex-column">
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_santa_rita_08.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Fabricação de forro de esteira, em Mariana, sem data. Foto: Feber.
            Fonte: Acervo Público Mineiro.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
